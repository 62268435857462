import { useSelector } from 'react-redux';

import { IUserAgreement } from '../types';
import { IGlobalState } from '../store';

export const useAvailableAgreements = (agreements: IUserAgreement[]): [IUserAgreement[], boolean] => {
  const cpa = useSelector((state: IGlobalState) => state.app.cpa);
  const visibleAgreements = agreements.filter((agreement) => {
    if (!agreement.requiredConfigurationPath) return true;
    return (cpa?.configuration as { [key: string]: unknown })?.[agreement.requiredConfigurationPath];
  });
  const showDialog = visibleAgreements.some((agreement) => agreement.requiredConfigurationPath);
  return [visibleAgreements, showDialog];
};
