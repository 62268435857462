export var PulumiOperation;
(function (PulumiOperation) {
    PulumiOperation["Up"] = "http://platform.cosmoconsult.com/ontology/infrastructure/Up";
    PulumiOperation["Preview"] = "http://platform.cosmoconsult.com/ontology/infrastructure/Preview";
    PulumiOperation["Destroy"] = "http://platform.cosmoconsult.com/ontology/infrastructure/Destroy";
    PulumiOperation["Refresh"] = "http://platform.cosmoconsult.com/ontology/infrastructure/Refresh";
})(PulumiOperation || (PulumiOperation = {}));
export var PulumiProgram;
(function (PulumiProgram) {
    PulumiProgram["Cluster"] = "cluster";
})(PulumiProgram || (PulumiProgram = {}));
