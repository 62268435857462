import { Json, Url } from '@cp/base-types';
import { ICardProps } from '@cpa/base-core/types';
import React, { useCallback } from 'react';

import ImageItemCard from '../ImageItemCard';

const DocumentItemCard: React.FC<ICardProps> = ({ data, item, onItemSelect, isSelected, hideSelectionMarker }) => {
  // If defined open 'documentUrl' in new tab with 'contentDisposition' query parameter set to 'inline'
  const onClick = useCallback(() => {
    if (Json.isStr(item.documentUrl)) {
      window.open(
        Url.build({
          hostAndOrBasePath: item.documentUrl,
          query: {
            contentDisposition: 'inline',
          },
        }).toString(),
        '_blank'
      );
    }
  }, [item.documentUrl]);

  // Resuse ImageItemCard with custom onClick
  return (
    <ImageItemCard
      data={data}
      item={item}
      onClick={onClick}
      onItemSelect={onItemSelect}
      isSelected={isSelected}
      hideSelectionMarker={hideSelectionMarker}
    />
  );
};

export default DocumentItemCard;
